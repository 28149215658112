<template>
    <div class="list-view">
        <div class="filters-container">
            <el-input placeholder="输入关键字" v-model="search" clearable class="filter-item"></el-input>
            <el-button @click="initData" type="primary" icon="el-icon-search" class="filter-item">搜索 </el-button>
            <el-button @click="editRow()" type="primary" icon="el-icon-plus" class="filter-item">添加 </el-button>
            <el-button
                @click="download"
                type="primary"
                icon="el-icon-download"
                :loading="downloading"
                class="filter-item"
                >导出EXCEL
            </el-button>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
        >
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="desc" label="描述"> </el-table-column>
            <el-table-column prop="type" label="类型" :formatter="typeFormatter"></el-table-column>
            <el-table-column prop="value" label="值" show-overflow-tooltip> </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="150">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row, true)" type="primary" size="mini" plain>编辑</el-button>
                </template>
            </el-table-column>

        </el-table>
        <div class="pagination-wrapper">
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>

        <el-dialog :visible.sync="showDialog" width="1200px" title="编辑设置" :close-on-click-modal="false">
            <el-form :model="formData" :rules="rules" ref="form" label-width="100px" label-position="right" size="small">
                <el-form-item prop="name" label="名称">
                    <el-input v-model="formData.name" :disabled="edit === true"></el-input>
                </el-form-item>
                <el-form-item prop="desc" label="描述">
                    <el-input v-model="formData.desc"></el-input>
                </el-form-item>
                <el-form-item prop="type" label="类型">
                    <el-select v-model="formData.type" placeholder="请选择" :disabled="edit === true">
                        <el-option
                            v-for="item in valueTypes"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'STRING'">
                    <el-input v-model="formData.value" ></el-input>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'DATETIME'">
                    <el-date-picker
                        v-model="formData.value"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择日期时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'DATE'">
                    <el-date-picker
                        v-model="formData.value"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择日期"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'TIME'">
                     <el-time-picker
                        v-model="formData.value"
                        value-format="HH:mm"
                        placeholder="请选择时间"
                        arrow-control
                    ></el-time-picker>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'BOOLEAN'">
                    <el-switch
                        v-model="formData.value"
                        active-text="是"
                        inactive-text="否"
                        active-value="1"
                        inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'NUMBER'">
                    <el-input-number v-model="formData.value" ></el-input-number>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'FILE'" >
                    <file-upload v-model="formData.value" :limit="1"></file-upload>
                </el-form-item>

                 <div class="list-wrap" v-if="formData.type === 'LIST'">
                     <el-form-item prop="optionMap" label="可选内容" >
                        <el-input type="textarea" v-model="formData.optionMap"  placeholder="请输入普通json格式" rows="5"   :disabled="edit === true" ></el-input>
                     </el-form-item>
                     <el-form-item prop="value" label="值" >
                         <el-select v-model="formData.value">
                             <el-option v-for="(value,key) in optionMapJson " :key="key"  :label="value" :value="key"></el-option>
                         </el-select>
                    </el-form-item>
                </div>
                <!-- 限价设置 -->
                <el-form-item label="是否禁用" v-if="formData.type === 'JSON'">
                    <el-switch v-model="priceLimitConfig.disabled"></el-switch>
                </el-form-item>
                <el-form-item label="藏品配置" v-if="formData.type === 'JSON'">
                    <el-table :data="priceLimitConfig.config" border>
                        <el-table-column prop="collectionId" label="藏品ID"></el-table-column>
                        <el-table-column prop="collectionName" label="藏品名称"></el-table-column>
                        <el-table-column prop="minPrice" label="寄售最低价"></el-table-column>
                        <el-table-column prop="maxPrice" label="寄售最高价"></el-table-column>
                        <el-table-column label="操作" align="center" fixed="right">
                            <template slot-scope="scope">
                                <el-button @click="editCollection(scope.$index)" type="primary" size="mini" plain>编辑</el-button>
                                <el-button @click="deleteCollection(scope.$index)" type="danger" size="mini" plain>删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-button type="success" size="mini" style="margin-left: 85%" @click="addCollection">新增藏品</el-button>
                </el-form-item>
                <!-- 限价设置 -->
                <el-form-item label="是否禁用" v-if="formData.type === 'BUY_LIMIT'">
                    <el-switch v-model="registerTimeConfig.disabled"></el-switch>
                </el-form-item>
                <!-- 注册时间限购 -->
                <el-form-item label="藏品配置" v-if="formData.type === 'BUY_LIMIT'">
                    <el-table :data="registerTimeConfig.config" border>
                        <el-table-column prop="collectionId" label="藏品ID"></el-table-column>
                        <el-table-column prop="collectionName" label="藏品名称"></el-table-column>
                        <el-table-column prop="registerTime" label="注册时间之后"></el-table-column>
                        <el-table-column label="操作" align="center" fixed="right">
                            <template slot-scope="scope">
                                <el-button @click="editRegisterCollection(scope.$index)" type="primary" size="mini" plain>编辑</el-button>
                                <el-button @click="deleteRegisterCollection(scope.$index)" type="danger" size="mini" plain>删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-button type="success" size="mini" style="margin-left: 85%" @click="addRegisterCollection">新增藏品</el-button>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button type="primary" size="mini" @click="save" :loading="saving">保存</el-button>
                <el-button size="mini" @click="showDialog = false" >取消</el-button>
            </span>
        </el-dialog>
        <!-- 寄售限价藏品编辑 -->
        <el-dialog :visible.sync="addOrEditCollection" width="800px" title="藏品编辑" :close-on-click-modal="false" :append-to-body="true">
            <el-form :model="collectionData" :rules="collectionRules" ref="form" label-width="100px" label-position="right" size="small">
                <el-form-item prop="collectionId" label="藏品ID">
                    <collection-search @select="onSelect" v-model="collectionData.collectionId" :projectId="formData.projectId">
                    </collection-search>
                </el-form-item>
                <el-form-item prop="minPrice" label="最低寄售价">
                    <el-input-number v-model="collectionData.minPrice" ></el-input-number>
                </el-form-item>
                <el-form-item prop="maxPrice" label="最高寄售价">
                    <el-input-number v-model="collectionData.maxPrice" ></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="collectionSubmit">提交</el-button>
                <el-button @click="()=>addOrEditCollection = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 注册时间限购藏品编辑 -->
        <el-dialog :visible.sync="addOrUpdateRegister" width="800px" title="藏品编辑" :close-on-click-modal="false" :append-to-body="true">
            <el-form :model="registerCollectionData" :rules="registerCollectionRules" ref="form" label-width="100px" label-position="right" size="small">
                <el-form-item prop="collectionId" label="藏品ID">
                    <collection-search @select="registerCollectionChange" v-model="registerCollectionData.collectionId" :projectId="formData.projectId">
                    </collection-search>
                </el-form-item>
                <el-form-item prop="registerTime" label="注册时间">
                    <el-date-picker
                        v-model="registerCollectionData.registerTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择日期时间"
                    ></el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="registerCollectionSubmit">提交</el-button>
                <el-button @click="()=>addOrUpdateRegister = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'SysConfigList',
    mixins: [pageableTable],
    data() {
        let validateOptionJson = (rule, value, callback) =>{

            if(this.parseOptionMap()){
               callback();
            }else{
                callback(new Error("请输入正确的json格式"));
            }

        }

        return {
            multipleMode: false,
            search: '',
            url: '/sysConfig/all',
            downloading: false,
            formData: {
                name: '',
                desc: '',
                value: '',
                type: 'STRING',
                optionMap:''
            },
            rules: {
                name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                desc: [{ required: true, message: '请输入描述', trigger: 'blur' }],
                type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
                value: [{ required: true, message: '请输入值', trigger: 'blur' }],
                optionMap: [
                    { required: true, message: '请输入选项内容', trigger: 'blur' },
                    { validator:validateOptionJson,  trigger: 'blur' },
                ]
            },
            collectionRules: {
                collectionId: [{ required: true, message: '请输入藏品ID', trigger: 'blur' }],
                maxPrice: [{ required: true, message: '请输入最大价格', trigger: 'blur' }],
                minPrice: [{ required: true, message: '请输入最小价格', trigger: 'blur' }],
            },
            registerCollectionRules: {
                collectionId: [{ required: true, message: '请选择藏品', trigger: 'change' }],
                registerTime: [{ required: true, message: '请选择注册时间', trigger: 'change' }],
            },
            collectionData: {
                collectionId: '',
                collectionName: '',
                maxPrice: 0,
                minPrice: 0
            },
            registerCollectionData: {
                collectionId: '',
                collectionName: '',
                registerTime: ''
            },
            showDialog: false,
            saving: false,
            edit: false,
            sortStr: 'createdAt,desc',
            valueTypes: [
                {
                    label: '字符串',
                    value: 'STRING'
                },
                {
                    label: '日期时间',
                    value: 'DATETIME'
                },
                {
                    label: '日期',
                    value: 'DATE'
                },
                {
                    label: '时间',
                    value: 'TIME'
                },
                {
                    label: '开关',
                    value: 'BOOLEAN'
                },
                {
                    label: '数字',
                    value: 'NUMBER'
                },
                {
                    label: '文件',
                    value: 'FILE'
                },
                {
                    label: '列表',
                    value: 'LIST'
                },
                {
                    label: '限价',
                    value: 'JSON'
                },
                {
                    label: '注册时间限购',
                    value: 'BUY_LIMIT'
                },
            ],
            htmlContents:[''],
            optionMapJson:{},
            priceLimitConfig: {
                disabled: true,
                config: [

                ]
            },
            registerTimeConfig: {
                disabled: true,
                config: [

                ]
            },
            addOrEditCollection: false,
            isEditCollection: false,
            editIndex: -1,
            addOrUpdateRegister: false
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        registerCollectionChange(e) {
            if (e) {
                this.$set(this.registerCollectionData, 'collectionId', e.id);
                this.$set(this.registerCollectionData, 'collectionName', e.name);
            }
        },
        onSelect(e) {
            if (e) {
                this.$set(this.collectionData, 'collectionId', e.id);
                this.$set(this.collectionData, 'collectionName', e.name);
            }
        },
        editCollection(index) {
            this.isEditCollection = true
            this.addOrEditCollection = true
            this.collectionData = this.priceLimitConfig.config[index]
            this.editIndex = index
        },
        deleteCollection(index) {
            this.$confirm('确认要删除么？', '警告', {type: 'error'}).then(() => {
                this.priceLimitConfig.config.splice(index, 1)
            })
        },
        editRegisterCollection(index) {
            this.isEditCollection = true
            this.addOrUpdateRegister = true
            this.registerCollectionData = this.registerTimeConfig.config[index]
            this.editIndex = index
        },
        deleteRegisterCollection(index) {
            this.$confirm('确认要删除么？', '警告', {type: 'error'}).then(() => {
                this.registerTimeConfig.config.splice(index, 1)
            })
        },
        registerCollectionSubmit() {
            this.$refs.form.validate((valid)=>{
                if(valid) {
                    let hasCollection = false
                    this.registerTimeConfig.config.map(item=>{
                        if(item.collectionId == this.registerCollectionData.collectionId) {
                            hasCollection = true
                        }
                    })
                    if(!this.isEditCollection) {
                        if(hasCollection) {
                            this.$message.error('该藏品ID已存在请进行编辑')
                            return
                        }
                        this.registerTimeConfig.config.push(this.registerCollectionData)
                        this.addOrUpdateRegister = false
                    } else {
                        this.registerTimeConfig.config[this.editIndex] = this.registerCollectionData
                        this.addOrUpdateRegister = false
                    }
                }
            })

        },
        collectionSubmit() {
            const MoneyReg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
            if(!MoneyReg.test(this.collectionData.minPrice.toString()) || !MoneyReg.test(this.collectionData.maxPrice.toString())) {
                this.$message.error('价格格式错误')
                return
            }
            if(this.collectionData.minPrice > this.collectionData.maxPrice) {
                this.$message.error('最低价格不能大于最大价格')
                return
            }
            let hasCollection = false
            this.priceLimitConfig.config.map(item=>{
                if(item.collectionId == this.collectionData.collectionId) {
                    hasCollection = true
                }
            })
            if(!this.isEditCollection) {
                if(hasCollection) {
                    this.$message.error('该藏品ID已存在请进行编辑')
                    return
                }
                this.priceLimitConfig.config.push(this.collectionData)
                this.addOrEditCollection = false
            } else {
                this.priceLimitConfig.config[this.editIndex] = this.collectionData
                this.addOrEditCollection = false
            }

        },
        addRegisterCollection() {
            this.addOrUpdateRegister = true
            this.registerCollectionData = {
                collectionId: '',
                registerTime: ''
            }
            this.isEditCollection = false
        },
        addCollection() {
            this.addOrEditCollection = true
            this.collectionData = {
                collectionId: '',
                maxPrice: 0,
                minPrice: 0
            }
            this.isEditCollection = false
        },
        beforeGetData() {
            return { search: this.search, sort: 'createdAt,desc' };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/sysConfigEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row, edit) {
            this.edit = edit;
            if (!row) {
                row = {
                    name: '',
                    desc: '',
                    value: '',
                    type: null
                };
            }
            this.formData = { ...row };
            if(this.formData.type === 'JSON') {
                this.priceLimitConfig = JSON.parse(row.value)
            }
            if(this.formData.type === 'BUY_LIMIT') {
                this.registerTimeConfig = JSON.parse(row.value)
            }
            this.showDialog = true;
            this.parseOptionMap();
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/sysConfig/excel', {
                    responseType: 'blob',
                    params: { size: 10000 }
                })
                .then(res => {
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let data = { ...this.formData };
                    this.saving = true;
                    if(data.type === 'JSON') {
                        const newListLength=new Set(this.priceLimitConfig.config.map(item=>item.collectionId)).size;
                        const listLength = this.priceLimitConfig.config.length;
                        if(listLength>newListLength){
                            this.$message.error('存在重复藏品ID，请检查')
                            this.saving = false;
                            return
                        }
                        data.value = JSON.stringify(this.priceLimitConfig)
                    }
                    if(data.type === 'BUY_LIMIT') {
                        const newListLength = new Set(this.registerTimeConfig.config.map(item=>item.collectionId)).size;
                        const listLength = this.registerTimeConfig.config.length;
                        if(listLength>newListLength){
                            this.$message.error('存在重复藏品ID，请检查')
                            this.saving = false;
                            return
                        }
                        data.value = JSON.stringify(this.registerTimeConfig)
                    }
                    this.$http
                        .post('/sysConfig/save', data, { body: 'json' })
                        .then(res => {
                            this.saving = false;
                            this.$message.success('成功');
                            this.showDialog = false;
                            this.getData();
                        })
                        .catch(e => {
                            console.log(e);
                            this.saving = false;
                            this.showDialog = false;
                            this.$message.error(e.error);
                        });
                } else {
                    return false;
                }
            });
        },
        typeFormatter(row, cell, cellValue) {
            let item = this.valueTypes.find(i => i.value === cellValue);
            return item ? item.label : '';
        },
        parseOptionMap(){
            let jsonData = {};
            try{
                jsonData = JSON.parse(this.formData.optionMap);
                this.optionMapJson = jsonData;
            }catch(e){
                //ignore
                return false;
            }

            return true;
        }
    }
};
</script>
<style lang="less" scoped>
    .list-wrap{
        padding-top:8px;
        border-top:solid 1px #eee;
    }
</style>
